@tailwind base;
@tailwind components;
@tailwind utilities;

/* third-party libraries CSS */

.testimonial-01 .swiper-pagination-bullet {
  @apply h-2.5 w-2.5 bg-stroke opacity-100;
}

.testimonial-01 .swiper-pagination-bullet-active {
  @apply w-5 rounded-full bg-primary;
}

.testimonial-01 .swiper-pagination {
  @apply relative pt-[50px];
}

#supportCheckbox:checked ~ div span {
  @apply opacity-100;
}

@layer base {
  body {
    @apply relative z-1 font-inter text-regular font-normal text-waterloo dark:text-manatee;
  }
}

@layer components {
  .dropdown {
    @apply -left-7.5 mt-2 hidden min-w-max flex-col gap-4 rounded-md border border-stroke bg-white px-8 py-5 shadow-solid-13 duration-300 ease-in dark:border-strokedark dark:bg-black xl:invisible xl:absolute xl:mt-0 xl:flex xl:w-[250px] xl:translate-y-10 xl:opacity-0 xl:before:absolute xl:before:-top-1.5 xl:before:left-10 xl:before:h-3 xl:before:w-3 xl:before:rotate-45 xl:before:border-l xl:before:border-t xl:before:border-stroke xl:before:bg-white xl:group-hover:visible xl:group-hover:translate-y-6.5 xl:group-hover:opacity-100 xl:dark:before:border-strokedark xl:dark:before:bg-black;
  }
}

@layer utilities {
  /* Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  .no-scrollbar {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
  }

  .img-white {
    filter: invert(0%) sepia(96%) saturate(15%) hue-rotate(249deg)
      brightness(100%) contrast(105%);
  }
}

.blog-details-docs {
  @apply rounded-lg border border-white p-7.5 shadow-solid-3 transition-all dark:border-strokedark dark:bg-blacksection xl:p-12.5;
}

.blog-details p {
  @apply mb-8 text-base leading-relaxed text-titlebgdark dark:text-waterloo;
}

.blog-details p strong {
  @apply text-primary dark:text-waterloo;
}

.blog-details h3 strong,
.blog-details h3 {
  @apply mb-10 text-xl font-semibold leading-tight text-black dark:text-white sm:text-2xl sm:leading-tight lg:text-xl lg:leading-tight xl:text-2xl xl:leading-tight;
}

.blog-details h4 strong,
.blog-details h4 {
  @apply text-xl font-semibold leading-tight text-black dark:text-white sm:text-2xl sm:leading-tight lg:text-xl lg:leading-tight xl:text-2xl xl:leading-tight;
}

.blog-details h5 strong,
.blog-details h5 {
  @apply mb-3 text-lg font-semibold leading-tight text-black dark:text-white sm:text-xl;
}

.blog-details h1 {
  @apply mb-4 text-3xl font-bold leading-tight text-black dark:text-white sm:text-4xl md:text-[45px] lg:text-4xl xl:text-[45px];
}

.blog-details h2 strong,
.blog-details h2 {
  @apply mb-4 text-[26px] font-bold leading-tight text-black dark:text-white sm:text-3xl md:text-4xl;
}

.blog-details ul,
.blog-details ol {
  @apply mb-10 list-inside list-disc text-titlebgdark;
}

.blog-details li,
.blog-details li {
  @apply mb-2 text-base text-titlebgdark dark:text-waterloo;
}

.blog-details blockquote {
  @apply relative z-10 mb-10 overflow-hidden rounded-sm bg-manatee p-8 text-center text-base font-medium italic text-black dark:bg-black dark:text-white md:py-9 lg:px-10;
}

pre {
  @apply relative mb-2 rounded-lg bg-strokedark px-4 py-2;
}

.prism-copy-button {
  @apply absolute right-2 top-2 h-[26px] rounded bg-white px-4 text-primary;
}

.active-tab {
  @apply bg-stroke text-black dark:bg-blackho dark:text-white;
}

// override phone input
.react-tel-input .selected-flag:hover,
.react-tel-input .selected-flag:focus {
  background-color: transparent !important;
}
